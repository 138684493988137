import CustomCursor from './CustomCursor';
import CustomTooltip from './CustomTooltip';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface Theme {
  activeDotStyles?: object;
  isGradientLine?: boolean;
  lineColor?: string;
  lineColorEnd?: string;
  lineColorStart?: string;
  shadowColor?: string;
}

const graphThemes: { [key: string]: Theme } = {
  greenGradient: {
    activeDotStyles: { fill: '#7FF1A3', r: 8, strokeWidth: 0 },
    isGradientLine: true,
    lineColorEnd: '#04D6E7',
    lineColorStart: '#18F255',
    shadowColor: '#18F255',
  },
  yellowGradient: {
    activeDotStyles: { fill: '#FCCE7E', r: 8, strokeWidth: 0 },
    isGradientLine: true,
    lineColorEnd: '#FF6B00',
    lineColorStart: '#FFED4C',
    shadowColor: '#FFED4C',
  },
};
interface Props {
  data: { xValue: string; yValue: number | null; label?: string }[];
  height: number;
  width: number;
  graphTheme?: 'yellowGradient' | 'greenGradient';
}

export default function LineChart({
  data,
  graphTheme = 'yellowGradient',
  height,

  width,
}: Props): JSX.Element {
  const selectedTheme = graphThemes[graphTheme];
  const {
    activeDotStyles,
    isGradientLine,
    lineColor,
    lineColorEnd,
    lineColorStart,
    shadowColor,
  } = selectedTheme;
  return (
    <ResponsiveContainer height={height} width="100%">
      <ComposedChart
        data={data}
        height={height}
        margin={{ bottom: 5, left: 5, right: 0, top: 35 }}
        style={{
          background: 'rgba(255, 255, 255, 0.7)',
          border: '1px solid #FFFFFF',
          borderRadius: '16px',
        }}
        width={width}>
        <defs>
          {isGradientLine && (
            <linearGradient id="lineColor" x1="0" x2="0" y1="0" y2="1">
              <stop offset="20%" stopColor={lineColorStart} stopOpacity={0.9} />
              <stop offset="95%" stopColor={lineColorEnd} stopOpacity={0.9} />
            </linearGradient>
          )}
          <filter height="200%" id="dropShadow">
            <feDropShadow
              dx="0"
              dy="15"
              floodColor={shadowColor}
              stdDeviation="9"
            />
          </filter>
        </defs>
        <CartesianGrid style={{ stroke: '#9092a333' }} vertical={false} />
        <Tooltip
          content={<CustomTooltip label={'nre'} />}
          cursor={
            <CustomCursor
              color={isGradientLine ? 'url(#lineColor)' : lineColor}
            />
          }
        />
        <XAxis
          axisLine={false}
          dataKey="xValue"
          padding={{ left: 25, right: 25 }}
          tickLine={false}
          tickMargin={10}
        />
        <YAxis
          allowDecimals={false}
          axisLine={false}
          dataKey="yValue"
          tickCount={7}
          tickLine={false}
          tickMargin={10}
          type="number"
        />

        <Line
          activeDot={activeDotStyles}
          dataKey="yValue"
          dot={false}
          filter="url(#dropShadow)"
          legendType="none"
          stroke={isGradientLine ? 'url(#lineColor)' : lineColor}
          strokeLinecap="round"
          strokeWidth={3}
          type="monotone"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
