import { AxiosError } from 'axios';
import { getContent } from '@/models/content';
import GradientBar from '@AppComponents/GradientBar';
import Image from 'next/image';
import { notifyAPIError } from '@/services/ErrorMonitoring';
import { roundToNearest } from '@/utils';
import styles from './styles.module.scss';
import { useQuery } from '@tanstack/react-query';
import useTypeList from '@/hooks/useTypeList';

function TrackCard({
  maxPopularTrackCount,
  trackId,
  trackListenCount,
}: {
  trackId: string;
  trackListenCount: number;
  maxPopularTrackCount: number;
}): JSX.Element {
  const { typeList }: { typeList: { [key: string]: string } } = useTypeList();

  const { data: track } = useQuery({
    onError: (error: AxiosError) => {
      notifyAPIError(error, {
        message: `Error while getting track`,
      });
    },
    queryFn: () => getContent(trackId),
    queryKey: [`track${trackId}`, trackId],
  });
  return (
    <>
      {track ? (
        <div className={styles.container}>
          <div className="flex align-center">
            <Image
              alt=""
              className={styles.trackImage}
              height={55}
              src={track.photoUrl}
              width={60}
            />
          </div>
          <div className={styles.trackInfoWrapper}>
            <div className={styles.topic}>{typeList[track.trackType]}</div>
            <GradientBar
              barAreaStyle={{ gap: 9 }}
              height={10}
              progress={
                (trackListenCount * 100) /
                roundToNearest(maxPopularTrackCount, 10)
              }
              title={track.title}
              titleStyle={{ fontSize: 16, lineHeight: '19px' }}
              value={trackListenCount}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default TrackCard;
