import EmptyStateList from '../EmptyStateList';
import GradientBar from '@AppComponents/GradientBar';
import Loader from '@/components/app/Loader';
import { roundToNearest } from '@/utils';
import styles from './styles.module.scss';
import useTypeList from '@/hooks/useTypeList';
import useTypesChosenReport from '@/hooks/useTypesChosenReport';

function ContentTypesChosen(): JSX.Element {
  const { typeList }: { typeList: { [key: string]: string } } = useTypeList();

  const { typeChosenAnalytics, typeChosenAnalyticsLoading } =
    useTypesChosenReport();
  const { maxCount = 0, types = [] } = typeChosenAnalytics || {};

  return (
    <div className={styles.container}>
      <div className={styles.title}>Content types chosen</div>
      <div className={styles.topicList}>
        {typeChosenAnalyticsLoading && <Loader inline />}
        {!typeChosenAnalyticsLoading &&
          types.length > 0 &&
          types.map(([typeKey, value]) => (
            <GradientBar
              key={typeKey}
              progress={(value * 100) / roundToNearest(maxCount, 10)}
              title={typeList[typeKey]}
              value={value}
            />
          ))}
      </div>
      {!typeChosenAnalyticsLoading && types.length === 0 && (
        <EmptyStateList
          imageURL="/images/topicEmptyChart.png"
          subTitle="When your employees start using Aura you will see content
       they choose"
        />
      )}
    </div>
  );
}

export default ContentTypesChosen;
