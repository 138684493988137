import { AxiosError } from 'axios';
import { getAnalytics } from '@/models/report';
import { notifyAPIError } from '@/services/ErrorMonitoring';
import useAuthUser from '@Hooks/authUser';
import { useQuery } from '@tanstack/react-query';
import { format, subDays } from 'date-fns';

const REPORT_NAME = 'dailyBusinessMetrics';

export default function useMetricsReport() {
  const { business } = useAuthUser();
  const { id: businessId } = business || {};

  const transformData = (
    data: {
      year: number;
      month: number | null;
      week: number | null;
      day: number | null;
      userCount: number;
      listenedCount: number;
      listenedTotal: number;
    }[]
  ) => {
    if (!data.length) return null;

    const latestReport = data[data.length - 1];
    return latestReport;
  };

  const {
    data: businessMetricsData,
    isInitialLoading: businessMetricsLoading,
  } = useQuery({
    enabled: !!businessId,
    onError: (error: AxiosError) => {
      notifyAPIError(error, {
        message: `Error while getting ${REPORT_NAME}`,
      });
    },
    queryFn: () =>
      getAnalytics({
        businessId,
        columns: 'userCount,listenedTotal',
        since: format(subDays(new Date(), 2), 'yyyy-MM-dd'), // We use last 2 days since a report might not have been yet generated for day - 1 due to timezone difference
        type: REPORT_NAME,
      }),
    queryKey: [REPORT_NAME, businessId],
    select: transformData,
  });

  return {
    businessMetricsData,
    businessMetricsLoading,
  };
}
