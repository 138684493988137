import Card from '@/components/app/cards/Card';
import { format } from 'date-fns';
import Image from 'next/image';
import LineChart from '@/components/graphs/LineChart';
import Loader from '@/components/app/Loader';
import styles from './styles.module.scss';
import useActiveUsersReport from '@/hooks/useActiveUsersReport';
import useMembers from '@/hooks/members';
import { useEffect, useState } from 'react';

const HIDE_ENGAGED_CHART = true;

function EngagedChart() {
  const { activeUserData, activeUserDataLoading } = useActiveUsersReport();
  const { average: activeUserCountAverage, list: activeUserCountList } =
    activeUserData || {};
  const [chartData, setChartDate] = useState<
    { xValue: string; yValue: number | null; label?: string }[]
  >([]);
  const { confirmedMemberList } = useMembers();
  const totalUsers = confirmedMemberList?.length || 0;
  const [hideChart, setHideChart] = useState<boolean>(HIDE_ENGAGED_CHART);

  useEffect(() => {
    if (
      activeUserCountList &&
      activeUserCountList.length > 0 &&
      !activeUserDataLoading
    ) {
      const formattedData = activeUserCountList.map(
        (item: {
          activeCount: number;
          month: number;
          year: number;
          day: number;
        }) => {
          const date = new Date().setFullYear(
            item.year,
            item.month - 1,
            item.day
          );
          return {
            label: format(date, 'dd MMMM'),
            xValue: '',
            yValue: item.activeCount,
          };
        }
      );
      setChartDate(formattedData);
    }
  }, [activeUserCountList, activeUserDataLoading]);

  useEffect(() => {
    if (activeUserDataLoading) return;
    if (
      !activeUserCountAverage ||
      (activeUserCountAverage / totalUsers) * 100 <= 30
    ) {
      setHideChart(true);
      return;
    }
  }, [
    activeUserCountAverage,
    totalUsers,
    activeUserCountList,
    activeUserDataLoading,
  ]);

  return (
    <div>
      {!hideChart && (
        <>
          <div className={styles.engagedTitle}>Engaged</div>
          <div>
            {activeUserDataLoading && <Loader />}
            {chartData.length > 0 && !activeUserDataLoading && (
              <LineChart data={chartData} height={300} width={600} />
            )}
            {chartData.length === 0 && !activeUserDataLoading && (
              <Card className={styles.noData}>
                <div className={styles.noDataWrapper}>
                  <div className={styles.title}>No Data Available</div>
                  <div className={styles.subTitle}>
                    When your employees start using Aura you will see engagement
                    charts
                  </div>
                </div>
                <div className={styles.chartImage}>
                  <Image alt="" fill src="/images/chart.png" />
                </div>
              </Card>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default EngagedChart;
