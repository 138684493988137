import styles from './styles.module.scss';

const gradients: { [key: string]: string } = {
  orange: 'linear-gradient(260.09deg, #FDF536 2.37%, #FE6442 97.08%)',
  purple: 'linear-gradient(260.09deg, #FF77B8 2.37%, #AC59FF 97.08%)',
};

interface Props {
  title: string | number;
  subTitle?: string;
  gradientColor?: 'orange' | 'purple';
}

export default function GradientCard({
  gradientColor,
  subTitle,
  title,
}: Props): JSX.Element {
  return (
    <div className={styles.card}>
      <div
        className={styles.backgroundCard}
        style={{
          background: gradientColor
            ? gradients[gradientColor]
            : gradients.orange,
        }}></div>
      <div className={styles.contentArea}>
        <div className={styles.title}>{title}</div>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      </div>
    </div>
  );
}
