import styles from './styles.module.scss';

const gradients: { [key: string]: string } = {
  blue: 'linear-gradient(270deg, #1DF5ED -3.23%, #4CCAFF 95.81%)',
};

interface Props {
  barAreaStyle?: object;
  barGradient?: 'blue';
  value?: number;
  height?: number | string;
  progress: number;
  title: string;
  titleStyle?: React.CSSProperties;
}

function GradientBar({
  barAreaStyle,
  barGradient,
  height = 17,
  progress,
  title,
  titleStyle,
  value,
}: Props) {
  return (
    <div className={styles.gradientBarWrapper}>
      <div className={styles.barArea} style={barAreaStyle}>
        <div className={styles.title} style={titleStyle}>
          {title}
        </div>
        <div
          className={styles.progressBar}
          style={{
            background: barGradient ? gradients[barGradient] : gradients.blue,
            height: height,
            width: `${progress}%`,
          }}></div>
      </div>
      <div className={styles.progress}>{value}</div>
    </div>
  );
}

export default GradientBar;
