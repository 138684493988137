import { AxiosError } from 'axios';
import { getAnalytics } from '@/models/report';
import { notifyAPIError } from '@/services/ErrorMonitoring';
import useAuthUser from '@Hooks/authUser';
import { useQuery } from '@tanstack/react-query';
import { format, subDays } from 'date-fns';

const REPORT_NAME = 'dailyBusinessAllPopularTracks';

export default function usePopularTracksReport() {
  const { business } = useAuthUser();
  const { id: businessId } = business || {};

  const transformData = (
    data: {
      year: number;
      month: number | null;
      week: number | null;
      day: number | null;
      tracks: {
        [key: string]: number;
      };
    }[]
  ) => {
    if (!data.length) return null;

    const latestReport = data[data.length - 1];
    const { tracks } = latestReport;
    const trackEntries: [string, number][] = Object.entries(tracks);
    const sortedTracks = trackEntries.sort((a, b) => b[1] - a[1]).slice(0, 10);
    const [mostPopularTrack] = sortedTracks;
    const [, maxCount] = mostPopularTrack;
    const tempData = {
      ...latestReport,
      maxCount,
      tracks: sortedTracks,
    };
    return tempData;
  };

  const {
    data: popularTrackAnalytics,
    isInitialLoading: popularTrackAnalyticsLoading,
  } = useQuery({
    enabled: !!businessId,
    onError: (error: AxiosError) => {
      notifyAPIError(error, {
        message: `Error while getting ${REPORT_NAME}`,
      });
    },
    queryFn: () =>
      getAnalytics({
        businessId,
        columns: 'tracks',
        since: format(subDays(new Date(), 2), 'yyyy-MM-dd'), // We use last 2 days since a report might not have been yet generated for day - 1 due to timezone difference
        type: REPORT_NAME,
      }),
    queryKey: [REPORT_NAME, businessId],
    select: transformData,
  });

  return {
    popularTrackAnalytics,
    popularTrackAnalyticsLoading,
  };
}
