import classnames from 'classnames';
import Image from 'next/image';
import styles from './styles.module.scss';

interface Props {
  subTitle?: string;
  imageURL: string;
  hideMessage?: boolean;
}

function EmptyStateList({ hideMessage, imageURL, subTitle }: Props) {
  return (
    <div className={styles.noData}>
      {!hideMessage && (
        <div className={styles.noDataWrapper}>
          <div className={styles.noDataTitle}>No Data Available</div>
          {subTitle && <div className={styles.noDataSubTitle}>{subTitle}</div>}
        </div>
      )}
      <div
        className={classnames(styles.emptyImage, {
          [styles.alignLeft]: hideMessage,
        })}>
        <Image alt="" fill src={imageURL} />
      </div>
    </div>
  );
}

export default EmptyStateList;
