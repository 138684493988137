import { AxiosError } from 'axios';
import { getAnalytics } from '@/models/report';
import { notifyAPIError } from '@/services/ErrorMonitoring';
import useAuthUser from '@Hooks/authUser';
import { useQuery } from '@tanstack/react-query';
import { format, getDate, getMonth, getYear, subDays } from 'date-fns';

const REPORT_NAME = 'dailyBusinessActiveUsers';

export default function useActiveUsersReport() {
  const { business } = useAuthUser();
  const { id: businessId } = business || {};

  const generateDefaultData = (startDate: Date, endDate: Date) => {
    const dates = [];
    let currentDate = startDate;
    const addDays = (currentDate: Date, days: number) => {
      const date = new Date(currentDate);
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push({
        activeCount: 0,
        day: getDate(currentDate),
        month: getMonth(currentDate) + 1,
        week: null,
        year: getYear(currentDate),
      });
      currentDate = addDays(currentDate, 1);
    }
    return dates;
  };

  const transformData = (
    data: {
      activeCount: number;
      day: number;
      month: number;
      week: null;
      year: number;
    }[]
  ) => {
    if (data.length === 0) return { average: 0, list: data };

    // generate default data for date range
    const defaultData = generateDefaultData(
      subDays(new Date(), 15),
      subDays(new Date(), 1)
    );

    // replace  actual data in default data
    data.forEach((dataItem) => {
      const itemIndex = defaultData.findIndex(
        (defaultItem) =>
          defaultItem.day === dataItem.day &&
          defaultItem.month === dataItem.month &&
          defaultItem.year === dataItem.year &&
          dataItem.activeCount
      );

      if (itemIndex !== -1) {
        defaultData[itemIndex].activeCount = dataItem.activeCount;
      }
    });
    const average =
      defaultData
        .slice(-14)
        .reduce((total, next) => total + next.activeCount, 0) / 14;

    return { average, list: defaultData };
  };

  const { data: activeUserData, isInitialLoading: activeUserDataLoading } =
    useQuery({
      enabled: !!businessId,
      onError: (error: AxiosError) => {
        notifyAPIError(error, {
          message: `Error while getting ${REPORT_NAME}`,
        });
      },
      queryFn: () =>
        getAnalytics({
          businessId,
          columns: 'activeCount',
          since: format(subDays(new Date(), 15), 'yyyy-MM-dd'),
          type: REPORT_NAME,
        }),
      queryKey: [REPORT_NAME, businessId],
      select: transformData,
    });

  return {
    activeUserData,
    activeUserDataLoading,
  };
}
