import { AxiosError } from 'axios';
import { getList } from '@/models/content';
import { notifyAPIError } from '@/services/ErrorMonitoring';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

const QUERY_STRING = 'topicList';

export default function useTopicList() {
  const transformData = useCallback(
    (
      data: {
        id: string;
        title?: string;
      }[]
    ) => {
      const topicList = data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.title || item.id }),
        {}
      );

      return topicList;
    },
    []
  );

  const { data: topicList = [] } = useQuery({
    onError: (error: AxiosError) => {
      notifyAPIError(error, {
        message: `Error while getting ${QUERY_STRING}`,
      });
    },
    queryFn: () =>
      getList({
        enrich: false,
        limit: 0,
        name: 'topic',
      }),
    queryKey: [QUERY_STRING],
    select: transformData,
  });

  return {
    topicList,
  };
}
