import AuraRing from '@AppComponents/AuraRing';
// import classnames from 'classnames';
import ContentTypesChosen from '@/components/dashboard/ContentTypesChosen';
import EngagedChart from '@/components/dashboard/EngagedChart';
import features from '@Utils/constants/features';
// import FilterByCard from '@/components/dashboard/FilterByCard';
import GradientCard from '@AppComponents/cards/GradientCard';
import Image from 'next/image';
import LayoutWithNav from '@/layouts/LayoutWithNav';
import LocalStorage from '@/services/LocalStorage';
import MostPopularTracks from '@/components/dashboard/MostPopularTracks';
import RestrictedFeature from '@/components/app/RestrictedFeature';
import styles from './styles.module.scss';
import TopicsChosen from '@/components/dashboard/TopicsChosen';
import useAuthUser from '@/hooks/authUser';
import useMembers from '@/hooks/members';
import useMetricsReport from '@/hooks/useMetricsReport';
import { useState } from 'react';
import useTrackPageView from '@/hooks/trackPageView';

export default function Dashboard() {
  const [hideWelcomeMessage, setHideWelocmeMessage] = useState<
    boolean | undefined
  >(LocalStorage.getItem(LocalStorage.KEYS.WELCOME_MESSAGE_CLOSED));
  // const [clientWindowHeight, setClientWindowHeight] = useState<number>(0);
  const { business, businessUser } = useAuthUser();
  const { confirmedMemberList } = useMembers();
  const { businessMetricsData, businessMetricsLoading } = useMetricsReport();
  const { listenedTotal } = businessMetricsData || {};

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setClientWindowHeight(window.scrollY);
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // });
  useTrackPageView();

  return (
    <LayoutWithNav>
      <RestrictedFeature feature={features.DASHBOARD} shouldRedirect>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {/* welcome user card */}
            {!hideWelcomeMessage && (
              <div className={styles.welcomeUserWrapper}>
                <div className={styles.welcomeUserContent}>
                  <div className="flex align-center">
                    <AuraRing className={styles.auraRing} />
                  </div>
                  <div className={styles.userInfo}>
                    <div className={styles.companyName}>{business?.name}</div>
                    <div
                      className={
                        styles.userName
                      }>{`Welcome ${businessUser?.name}!`}</div>
                    <div className={styles.greet}>
                      {`We're so excited to have you here`}
                    </div>
                  </div>
                </div>
                <div
                  className="clickable"
                  onClick={() => {
                    LocalStorage.setItem(
                      LocalStorage.KEYS.WELCOME_MESSAGE_CLOSED,
                      true
                    );
                    setHideWelocmeMessage(true);
                  }}>
                  <Image
                    alt="Close"
                    height={14}
                    src={'/icons/grayClose.svg'}
                    width={14}
                  />
                </div>
              </div>
            )}

            <div className={styles.dashboardTitle}>Dashboard</div>

            {/* filterby area  */}
            {/* <div
            className={classnames(styles.filtersWrapper, {
              [styles.filterFixTop]: clientWindowHeight > 240,
            })}>
            <div className={styles.filterbyTexr}>Filter by</div>
            <div className={styles.filterArea}>
              <FilterByCard active title="Total" />
              <FilterByCard title="Monthly" />
              <FilterByCard title="For the period" />
            </div>
          </div> */}

            {/* State cards  */}
            {!businessMetricsLoading && (
              <div className={styles.stateCardArea}>
                <GradientCard
                  gradientColor="purple"
                  subTitle="Registered"
                  title={confirmedMemberList?.length || 0}
                />
                <GradientCard
                  gradientColor="orange"
                  subTitle="Minutes listened"
                  title={listenedTotal || 0}
                />
              </div>
            )}

            {/* Engaged chart */}
            <EngagedChart />

            {/* topic and content type chosen */}
            <div className={styles.choosenTopicArea}>
              <TopicsChosen />
              <ContentTypesChosen />
            </div>

            {/* most popular tracks */}
            <MostPopularTracks />
          </div>
        </div>
      </RestrictedFeature>
    </LayoutWithNav>
  );
}
