import classnames from 'classnames';
import EmptyStateList from '../EmptyStateList';
import Loader from '@/components/app/Loader';
import styles from './styles.module.scss';
import TrackCard from './TrackCard';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import usePopularTracksReport from '@/hooks/usePopularTracksReport';

function MostPopularTracks(): JSX.Element {
  const isMobile = useDeviceDetect();
  const { popularTrackAnalytics, popularTrackAnalyticsLoading } =
    usePopularTracksReport();
  const { maxCount = 0, tracks = [] } = popularTrackAnalytics || {};

  return (
    <div>
      <div className={styles.title}>Most popular tracks</div>
      <div className={styles.trackList}>
        {popularTrackAnalyticsLoading && (
          <Loader inline style={{ width: '100%' }} />
        )}
        {!popularTrackAnalyticsLoading &&
          tracks &&
          tracks?.length > 0 &&
          tracks.map(([trackId, value]) => (
            <TrackCard
              key={trackId}
              maxPopularTrackCount={Math.ceil(maxCount)}
              trackId={trackId}
              trackListenCount={value}
            />
          ))}
      </div>

      {!popularTrackAnalyticsLoading && tracks.length === 0 && (
        <div className={styles.emptyStateList}>
          <div className={styles.borderTop}>
            <EmptyStateList
              imageURL="/images/popularTrackEmpty.png"
              subTitle="When your employees start using Aura you will see top tracks they are listening"
            />
          </div>
          {!isMobile && (
            <div className={classnames(styles.borderTop, styles.hideEmptyUI)}>
              <EmptyStateList
                hideMessage
                imageURL="/images/popularTrackEmpty.png"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MostPopularTracks;
