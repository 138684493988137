import EmptyStateList from '../EmptyStateList';
import GradientBar from '@AppComponents/GradientBar';
import Loader from '@/components/app/Loader';
import { roundToNearest } from '@/utils';
import styles from './styles.module.scss';
import useTopicList from '@/hooks/useTopicList';
import useTopicsChosenReport from '@/hooks/useTopicsChosenReport';

function TopicsChosen(): JSX.Element {
  const { topicList }: { topicList: { [key: string]: string } } =
    useTopicList();

  const { topicChosenAnalytics, topicChosenAnalyticsLoading } =
    useTopicsChosenReport();

  const { maxCount = 0, topics = [] } = topicChosenAnalytics || {};

  return (
    <div className={styles.container}>
      <div className={styles.title}>Topics chosen</div>
      <div className={styles.topicList}>
        {topicChosenAnalyticsLoading && <Loader />}
        {!topicChosenAnalyticsLoading &&
          topics &&
          topics.length > 0 &&
          topics.map(([topicKey, value]) => (
            <GradientBar
              key={topicKey}
              progress={(value * 100) / roundToNearest(maxCount, 10)}
              title={topicList[topicKey]}
              value={value}
            />
          ))}
      </div>
      {!topicChosenAnalyticsLoading && topics.length === 0 && (
        <EmptyStateList
          imageURL="/images/topicEmptyChart.png"
          subTitle="When your employees start using Aura you will see topics they choose"
        />
      )}
    </div>
  );
}

export default TopicsChosen;
