import { AxiosError } from 'axios';
import { getAnalytics } from '@/models/report';
import { notifyAPIError } from '@/services/ErrorMonitoring';
import useAuthUser from '@Hooks/authUser';
import { useQuery } from '@tanstack/react-query';
import { format, subDays } from 'date-fns';

const REPORT_NAME = 'dailyBusinessTotalListenByTopic';

export default function useTopicsChosenReport() {
  const { business } = useAuthUser();
  const { id: businessId } = business || {};

  const transformData = (
    data: {
      year: number;
      month: number | null;
      week: number | null;
      day: number | null;
      topics: {
        [key: string]: number;
      };
    }[]
  ) => {
    if (!data.length) return null;

    const latestReport = data[data.length - 1];
    const { topics } = latestReport;
    const topicEntries: [string, number][] = Object.entries(topics);
    const sortedTopics = topicEntries.sort((a, b) => b[1] - a[1]).slice(0, 5);
    const [mostPopularTopic] = sortedTopics;
    const [, maxCount] = mostPopularTopic;
    return {
      ...latestReport,
      maxCount,
      topics: sortedTopics,
    };
  };

  const {
    data: topicChosenAnalytics,
    isInitialLoading: topicChosenAnalyticsLoading,
  } = useQuery({
    enabled: !!businessId,
    onError: (error: AxiosError) => {
      notifyAPIError(error, {
        message: `Error while getting ${REPORT_NAME}`,
      });
    },
    queryFn: () =>
      getAnalytics({
        businessId,
        columns: 'topics',
        since: format(subDays(new Date(), 2), 'yyyy-MM-dd'), // We use last 2 days since a report might not have been yet generated for day - 1 due to timezone difference
        type: REPORT_NAME,
      }),
    queryKey: [REPORT_NAME, businessId],
    select: transformData,
  });

  return {
    topicChosenAnalytics,
    topicChosenAnalyticsLoading,
  };
}
