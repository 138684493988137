import { AxiosError } from 'axios';
import { getAnalytics } from '@/models/report';
import { notifyAPIError } from '@/services/ErrorMonitoring';
import useAuthUser from '@Hooks/authUser';
import { useQuery } from '@tanstack/react-query';
import { format, subDays } from 'date-fns';

const REPORT_NAME = 'dailyBusinessTotalListenByType';

export default function useTypesChosenReport() {
  const { business } = useAuthUser();
  const { id: businessId } = business || {};

  const transformData = (
    data: {
      year: number;
      month: number | null;
      week: number | null;
      day: number | null;
      types: {
        [key: string]: number;
      };
    }[]
  ) => {
    if (!data.length) return null;

    const latestReport = data[data.length - 1];
    const { types } = latestReport;
    const typeEntries: [string, number][] = Object.entries(types);
    const sortedTypes = typeEntries.sort((a, b) => b[1] - a[1]).slice(0, 5);
    const [mostPopularType] = sortedTypes;
    const [, maxCount] = mostPopularType;
    return {
      ...latestReport,
      maxCount,
      types: sortedTypes,
    };
  };

  const {
    data: typeChosenAnalytics,
    isInitialLoading: typeChosenAnalyticsLoading,
  } = useQuery({
    enabled: !!businessId,
    onError: (error: AxiosError) => {
      notifyAPIError(error, {
        message: `Error while getting ${REPORT_NAME}`,
      });
    },
    queryFn: () =>
      getAnalytics({
        businessId,
        columns: 'types',
        since: format(subDays(new Date(), 2), 'yyyy-MM-dd'), // We use last 2 days since a report might not have been yet generated for day - 1 due to timezone difference
        type: REPORT_NAME,
      }),
    queryKey: [REPORT_NAME, businessId],
    select: transformData,
  });

  return {
    typeChosenAnalytics,
    typeChosenAnalyticsLoading,
  };
}
