import { Rectangle } from 'recharts';

interface Points {
  x?: number;
  y?: number;
}
interface Props {
  color?: string;
  height?: number;
  points?: Points[];
}

const CustomCursor = ({
  color = '#FFED4C',
  height,
  points = [],
}: Props): JSX.Element => {
  const [{ x = 0, y = 0 }] = points;

  return (
    <Rectangle
      fill={color}
      fillOpacity={0.2}
      height={height}
      width={60}
      x={x - 30}
      y={y}
    />
  );
};

export default CustomCursor;
