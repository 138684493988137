import styles from './styles.module.scss';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

const CustomTooltip = ({
  active,
  label,
  payload,
}: TooltipProps<ValueType, NameType>): JSX.Element => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customeTooltip}>
        <div className={styles.tooltipLabel}>
          {payload[0]?.payload?.label || label}
        </div>
        <div className={styles.tooltipValue}>{payload[0].value}</div>
      </div>
    );
  }

  return <></>;
};

export default CustomTooltip;
