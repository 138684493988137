import { AxiosError } from 'axios';
import config from '@/config';
import Http from '@Services/Http';
import Logger from '@/services/Logger';
import { notifyAPIError } from '@/services/ErrorMonitoring';

async function getContent(id: string) {
  try {
    Logger.debug('Getting tracks', { id });
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      json: true,
      method: 'GET',
      url: `${config.api.auraServices}/contents/${id}`,
    };
    const response = await Http.handle(options);

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    notifyAPIError(error as AxiosError, {
      message: 'Error while getting content',
    });
  }
}

async function getList({
  enrich,
  limit,
  name,
  page,
}: {
  enrich?: boolean;
  limit?: number;
  name: string;
  page?: number;
}) {
  Logger.debug(`Getting ${name} List`);
  const data = { enrich, limit, name, page };
  const options = {
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    json: true,
    method: 'POST',
    url: `${config.api.auraServices}/lists/list`,
  };
  const response = await Http.handle(options);

  if (response && response.status === 200 && response.data) {
    return response.data;
  }
}

getContent.QUERY_STRING = 'get-content';

export { getContent, getList };
