import config from '@/config';
import Http from '@Services/Http';
import Logger from '@/services/Logger';

async function getAnalytics({
  businessId,
  columns,
  period,
  since,
  type,
  until,
}: {
  businessId?: string;
  columns?: string;
  period?: string;
  since?: string;
  type: string;
  until?: string;
}) {
  if (!businessId) return;
  Logger.debug('Getting analytic report', { businessId, type });

  const params = {
    businessId,
    columns,
    period,
    since,
    type,
    until,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    json: true,
    method: 'GET',
    params,
    url: `${config.api.auraServices}/reports/analytics`,
  };
  const response = await Http.handle(options);

  if (response && response.status === 200 && response.data) {
    return response.data;
  }
}

export { getAnalytics };
